<template>
  <uploader :options="options" :file-status-text="fileStatusText" ref="uploader"
            @file-complete="fileComplete" @complete="complete" class="uploader-aibooks">
    <uploader-unsupport></uploader-unsupport>
    <uploader-drop>
      <p>Перетащите файл бекапа (ZIP-архив) в эту область или</p>
      <uploader-btn :attrs="attrs" :single="true">выберите файл</uploader-btn>
    </uploader-drop>
    <uploader-list></uploader-list>
  </uploader>
</template>

<script>
import {plural} from "@/helpers/messages";

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    atoken: {
      type: String,
      required: true
    },
    batch: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      options: {
        target: this.url,
        chunkSize: 9007199254740992,
        maxChunkRetries: 1,
        chunkRetryInterval: 5000,
        testChunks: false,
        simultaneousUploads: 1,
        query: {atoken: this.atoken, batch: this.batch},
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          const numberValue = parsedTimeRemaining.match(/\d+/)[0]; 
          return parsedTimeRemaining
              .replace(/\syears?/, ' ' + plural(['год', 'года', 'лет'], numberValue))
              .replace(/\days?/, ' ' + plural(['день', 'дня', 'дней'], numberValue))
              .replace(/\shours?/, ' ' + plural(['час', 'часа', 'часов'], numberValue))
              .replace(/\sminutes?/, ' ' + plural(['минута', 'минуты', 'минут'], numberValue))
              .replace(/\sseconds?/, ' ' + plural(['секунда', 'секунды', 'секунд'], numberValue))
        },
      },
      fileStatusText: {
        success: 'успешно',
        error: 'ошибка',
        uploading: 'загружается',
        paused: 'на паузе',
        waiting: 'ожидание'
      },
      attrs: {
        accept: ['application/zip', 'application/octet-stream'],
      }
    }
  },
  methods: {
    complete() {
      //console.log('complete', arguments)
      location.reload();
    },
    fileComplete() {
      console.log('файл загружен', arguments)
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.uploader = this.$refs.uploader.uploader
    })
  }
}
</script>

<style>
.uploader-aibooks {
  max-width: 880px;
  min-width: 20%;
  padding: 15px;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4);
}

.uploader-aibooks .uploader-btn {
  margin-right: 4px;
}

.uploader-aibooks .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>