import Vue from 'vue'
import uploader from 'vue-simple-uploader'
import App from './App.vue'

Vue.use(uploader)
const vues = document.querySelectorAll(".simple-uploader");
const each = Array.prototype.forEach;
/* eslint-disable no-new */
each.call(vues, (el) => new Vue({
    render(createElement) {
        return createElement(App, {
            props: {
                url: el.getAttribute('data-url'),
                atoken: el.getAttribute('data-atoken'),
                batch: el.getAttribute('data-batch'),
            }
        })
    },
}).$mount(el))

